<template>
    <div class="version-view">
        <el-timeline class="time-line">
            <el-timeline-item v-for="(item, index) in versionInfo"
                              :timestamp="item.updateTime" placement="top">
                <el-card class="time-card">
                    <h4>{{item.info}}</h4>
                    <p>{{item.user}} 提交于 {{item.updateTime}}</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>

</template>

<script>
export default {
    name: "VersionLog",

    data(){
        return{
            versionInfo: [],
        }
    },

    methods: {
        getVersionInfo(){
            this.getRequest('/collect/version').then(resp => {
                if(resp.success){
                    this.versionInfo = resp.data;
                }
            })
        }
    },

    mounted() {
        this.getVersionInfo();
    }

}
</script>

<style scoped>
/*整体布局*/
.version-view {
    width: 100%;
}

/*时间线容器*/
.time-line {
    margin-top: 20px;
}

/*卡片容器*/
.time-card {
    width: 40%;
}

</style>